import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { RouteComponentProps, useParams } from 'react-router';
import Modal from 'antd/lib/modal/Modal';

import { Form, Overlay, Spinner } from '~/UI';
import { AdminFormLayout } from '~/components/Admin/AdminLayout/AdminFormLayout';
import { requiredRule, formErrorScroll } from '~/utils';
import { QuartersData } from '~/services/api/anza';
import {
  addQuarterYearKeysToModulePricingData,
  getModulePricingFromForm,
  getQuartersWithUpdates,
} from '~/utils/modulePricingAvailability';
import ModulePricingFormGroup, {
  CompanyModulesDataWithYearsDisplayAndKey,
} from '~/components/SolarModules/ModulePricingFormGroup';
import { blockEnterKeyEvents } from '~/utils/events';
import {
  useGetAllConnectorTypesQuery,
  useCreateModulePricingMutation,
  useUpdateModulePricingMutation,
  useGetAllCompaniesQuery,
  useDeleteModuleMutation,
} from '~/store/api';
import {
  CELL_TECHNOLOGY_OPTIONS,
  DOMESTIC_CONTENT_OR_ASSEMBLY_OPTIONS,
  MODULE_MAX_QUARTER_DATA_SHOWN,
  MODULE_TAGS,
} from '~/constants/modules';
import { FileResponseStatus } from '~/types/admin';
import useModulePricingFormGroupRules from '~/hooks/useModulePricingFormGroupRules';
import { ModuleFile } from '~/types/file';
import { CompanyType } from '~/types/users';
import { rtkService, ModuleResponse } from '~/types/rtkApi';
import { ModuleType, Quarter } from '~/types/modules';
import getCheckInvalidFormFields from '~/utils/getCheckInvalidFormFields';
import FormFooter from '~/components/FormFooter';
import useFormDirty from '~/hooks/useFormDirty';

import { ADMIN_ROUTES } from '../../../router/AdminRoutes';
import CompanyFormGroup from './AdminModulesForm/CompanyFormGroup';
import PricingAndAvailabilityGroup from './AdminModulesForm/PricingAndAvailabilityGroup';
import ModuleInformationFormGroup from './AdminModulesForm/ModuleInformationFormGroup';
import FilesFormGroup from './AdminModulesForm/FilesFormGroup';

const getNewModuleQuarters = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentQuarter = Math.floor((today.getMonth() + 3) / 3);

  const quartersArray = [];
  let year = currentYear;
  let quarter = currentQuarter;
  for (let i = 0; i < MODULE_MAX_QUARTER_DATA_SHOWN; i += 1) {
    quartersArray.push({
      availability_mw: null,
      unavailability_reason: null,
      quarter,
      year,
      ddp_east_coast_port: null,
      key: `q${quarter}-${year}`,
    });
    if (quarter === 4) {
      quarter = 0;
      year += 1;
    }
    quarter += 1;
  }
  return quartersArray;
};

interface AdminModulesFormProps extends RouteComponentProps {
  service: rtkService;
  onFinishCallback: (value?: boolean) => void;
  createMode?: boolean;
}

const AdminModulesForm: React.FC<AdminModulesFormProps> = ({
  service,
  history,
  createMode,
  onFinishCallback = () => {},
}) => {
  const { id }: { id?: string | null } = createMode
    ? { id: null }
    : useParams() || {};
  const isNew = !id;
  const [form] = Form.useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const companyId = Form.useWatch('company_id', form);
  const [formError, setFormError] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [indexedCompanies, setIndexedCompanies] = useState<CompanyType[]>([]);
  const [selectedModule, setSelectedModule] =
    useState<CompanyModulesDataWithYearsDisplayAndKey>();
  const [lastUpdated, setLastUpdated] = useState<Date>();
  const [isAnzaActivated, setIsAnzaActivated] = useState<boolean>();
  const { data = null, isLoading = false } = id
    ? (service.get({ id }) as ModuleResponse)
    : {};

  const { data: companies } = useGetAllCompaniesQuery();
  const isFirstSolarSelected = useMemo(() => {
    const firstSolar = companies?.find(
      (company) => company.name === 'First Solar'
    );
    if (!firstSolar?.id || !companyId) return false;
    return companyId === firstSolar?.id;
  }, [companyId, companies]);
  const [createModule, createModuleRes] = useCreateModulePricingMutation();
  const [updateModule, { isLoading: isLoadingUpdate }] =
    useUpdateModulePricingMutation();
  const [deleteModule] = useDeleteModuleMutation();

  const isAnzaActive = Form.useWatch('is_anza_activated', form) || false;

  const goBack = () => {
    if (!createMode) {
      history.push(ADMIN_ROUTES.MODULES.path.default);
    } else {
      onFinishCallback(false);
    }
  };

  const { data: connectorTypesData } = useGetAllConnectorTypesQuery(undefined, {
    skip: false,
  });

  const handleCancelDelete = () => setDeleteModalOpen(false);

  const handleGoThroughWithDelete = async () => {
    await deleteModule({ moduleId: id! });
    setDeleteModalOpen(false);
    goBack();
  };

  const checkInvalid = getCheckInvalidFormFields(form, () => {});

  const {
    isDirty,
    onValuesChange: onValuesChangeIsDirty,
    setIsDirty,
  } = useFormDirty();

  const onSave = async () => {
    try {
      await form.validateFields();
      setIsDirty(false);
    } catch (error) {
      setFormSubmitted(false);
      formErrorScroll();
    }
  };

  const {
    priceRequiredFormNames,
    availabilityRequiredFormNames,
    onValuesChange: onValuesChangeUpdateGroupRules,
  } = useModulePricingFormGroupRules();

  const onValuesChange = (
    ...params: Parameters<typeof onValuesChangeUpdateGroupRules>
  ) => {
    onValuesChangeUpdateGroupRules(...params);
    onValuesChangeIsDirty();
  };

  useEffect(() => {
    if (companies) {
      const indexCompanies = R.indexBy(R.prop('id'), companies);
      setIndexedCompanies(Object.values(indexCompanies));
    }

    if (!id) {
      const newModule = {
        quarters: getNewModuleQuarters(),
      } as unknown as ModuleType;

      setSelectedModule(
        addQuarterYearKeysToModulePricingData(
          newModule
        ) as unknown as CompanyModulesDataWithYearsDisplayAndKey
      );
      return;
    }

    if (data) {
      setSelectedModule(
        addQuarterYearKeysToModulePricingData(
          data
        ) as unknown as CompanyModulesDataWithYearsDisplayAndKey
      );
      setLastUpdated(data.pricing_availability_confirmed_at);
      setIsAnzaActivated(!data.is_anza_deactivated);

      R.pipe(
        (value: ModuleType) => ({
          ...value,
          is_anza_activated: !value.is_anza_deactivated,
          ul_listed: value.ul_listed,
          iec_listed: value.iec_listed,
          cec_listed: value.cec_listed,
          anza_pan_file_expected_availability_date:
            value.anza_pan_file_expected_availability_date
              ? dayjs(value.anza_pan_file_expected_availability_date)
              : null,
          third_party_pan_file_expected_availability_date:
            value.third_party_pan_file_expected_availability_date
              ? dayjs(value.third_party_pan_file_expected_availability_date)
              : null,
          manufacturer_pan_file_expected_availability_date:
            value.manufacturer_pan_file_expected_availability_date
              ? dayjs(value.manufacturer_pan_file_expected_availability_date)
              : null,
          connector_type: value.connector_type ? value.connector_type : [],
          lead_length: value.lead_length ? value.lead_length : [],
          tier_1: data.tags.includes(MODULE_TAGS.TIER_1.value),
          cell_tecnology: data.tags.filter((tag) =>
            CELL_TECHNOLOGY_OPTIONS.map(R.prop('value')).includes(tag)
          ),
          domestic_content_or_assembly: data.tags.filter((tag) =>
            DOMESTIC_CONTENT_OR_ASSEMBLY_OPTIONS.map(R.prop('value')).includes(
              tag
            )
          ),
        }),
        R.omit(['is_anza_deactivated']),
        form.setFieldsValue
      )(data);

      const formValues = form.getFieldsValue(true);
      const fieldsValues = formValues.quarters.reduce(
        (
          acc: { [key: string]: string | number | null },
          curr: QuartersData
        ) => {
          acc[`ddp_east_coast_port_q${curr.quarter}-${curr.year}`] =
            curr.ddp_east_coast_port;
          acc[`availability_mw_q${curr.quarter}-${curr.year}`] =
            curr.availability_mw;
          acc[`unavailability_reason_q${curr.quarter}-${curr.year}`] =
            curr.unavailability_reason;
          return acc;
        },
        {}
      );
      form.setFieldsValue({ ...fieldsValues });
      checkInvalid();
    }
  }, [createModuleRes.fulfilledTimeStamp, data, companies]);

  const panFileParseFailed = (panFileStatus: FileResponseStatus) =>
    panFileStatus && !panFileStatus.success && panFileStatus.error?.message;

  const onFinish = async () => {
    const { pan_file_path: formHasPanFile } = form.getFieldsValue(true);
    try {
      const {
        files,
        tier_1,
        cell_tecnology,
        domestic_content_or_assembly,
        ...otherFormValues
      } = form.getFieldsValue(true);

      const updatedFiles =
        files
          ?.map((file: ModuleFile) => {
            const { newlyAddedId, searchFileUuid, ...other } = file;
            return { ...other };
          })
          .filter(
            (file: ModuleFile) =>
              file.file_type !== 'thirdPartyPanSupportingFilesZip' &&
              file.file_type !== 'manufacturerPanSupportingFilesZip' &&
              file.file_type !== 'anzaPanSupportingFilesZip'
          ) || [];

      const payload = R.pipe(
        R.over<any, boolean>(R.lensProp('is_anza_activated'), R.not),
        RA.renameKeys({
          is_anza_activated: 'is_anza_deactivated',
        })
      )({ files: updatedFiles, ...otherFormValues });

      const updatedQtrs = getModulePricingFromForm(
        selectedModule ?? ({} as CompanyModulesDataWithYearsDisplayAndKey),
        form
      ).quarters;

      // Only send the updated quarters off to the database to avoid unwanted logs.
      (payload as ModuleType).quarters = getQuartersWithUpdates(
        selectedModule?.quarters as unknown as Quarter[],
        updatedQtrs as unknown as Quarter[]
      );

      // Adding all selected Module Attributes in the "tags" property
      (payload as ModuleType).tags = R.reject(R.isNil, [
        tier_1 ? MODULE_TAGS.TIER_1.value : null,
        ...(cell_tecnology || []),
        ...(domestic_content_or_assembly || []),
      ]);

      const response: ModuleResponse = id
        ? await updateModule({ id: +id, ...payload } as ModuleType).unwrap()
        : await createModule({ payload } as unknown as ModuleType).unwrap();

      setLastUpdated(response.data?.pricing_availability_confirmed_at);
      setIsAnzaActivated(!response.data?.is_anza_deactivated);

      const { panFileStatus, quarters } = response.data;
      setSelectedModule(
        addQuarterYearKeysToModulePricingData({
          ...selectedModule,
          quarters,
        } as unknown as ModuleType) as unknown as CompanyModulesDataWithYearsDisplayAndKey
      );
      if (panFileParseFailed(panFileStatus!)) {
        return setFormError(panFileStatus!.error.message);
      }

      if (formHasPanFile && panFileStatus && panFileStatus.success) {
        history.go(0);
      } else if (isNew) {
        history.push(ADMIN_ROUTES.MODULES.path.default);
      }

      form.setFieldValue('files', response?.data?.files);
      return true;
    } catch (error: any) {
      setFormError(
        error?.data ||
          error?.response?.data?.error ||
          'An error occurred while saving your data.'
      );
      return false;
    } finally {
      setFormSubmitted(false);
      setIsDirty(false);
      form.resetFields(['pan_file_data']);
    }
  };

  const loading = isLoading || isLoadingUpdate;
  const disabledSaveButton = createModuleRes.isLoading || formSubmitted;
  useEffect(() => {
    if (!loading) {
      setFormSubmitted(false);
    }
  }, [loading]);

  return (
    <AdminFormLayout>
      <Form
        disabled={disabledSaveButton}
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        style={{ width: '100%' }}
        onKeyDown={blockEnterKeyEvents}
        initialValues={{ pan_file_data: [] }}
      >
        {loading && (
          <Overlay style={{ height: '100%', width: '100%', zIndex: 9999 }}>
            <Spinner />
          </Overlay>
        )}

        <CompanyFormGroup
          companies={indexedCompanies}
          requiredRule={requiredRule}
          module={selectedModule}
          createMode={createMode!}
          isAnzaActive={isAnzaActive}
          selectedCompany={form.getFieldValue('company_id')}
        />

        <PricingAndAvailabilityGroup />

        <div>
          <ModulePricingFormGroup
            moduleData={
              selectedModule ?? ({} as CompanyModulesDataWithYearsDisplayAndKey)
            }
            lastUpdated={lastUpdated!}
            isActive={!!isAnzaActivated}
            priceRequiredFormNames={priceRequiredFormNames}
            availabilityRequiredFormNames={availabilityRequiredFormNames}
            form={form}
          />
        </div>

        <ModuleInformationFormGroup
          requiredRule={requiredRule}
          isAnzaActive={isAnzaActive}
          connectorTypesData={connectorTypesData}
          form={form}
          checkInvalid={checkInvalid}
          isFirstSolar={isFirstSolarSelected}
        />

        <FilesFormGroup
          form={form}
          moduleUuid={selectedModule?.uuid ?? ''}
          checkIsInvalid={checkInvalid}
          loading
          isFirstSolarSelected={isFirstSolarSelected}
        />

        <Modal
          title="Are you sure?"
          open={deleteModalOpen}
          onOk={handleGoThroughWithDelete}
          onCancel={handleCancelDelete}
        >
          <p>Are you sure you want to delete this item?</p>
          <p>
            Once you do it, all the information related to this item might be
            lost.
          </p>
        </Modal>
        <FormFooter
          formError={formError}
          isDirty={isDirty}
          saveButtonProps={{
            disabled: disabledSaveButton,
            loading,
            onClick(e) {
              e.preventDefault();
              if (!formSubmitted) {
                setFormSubmitted(true);
                onSave();
                form.submit();
              }
            },
          }}
        />
      </Form>
    </AdminFormLayout>
  );
};

AdminModulesForm.defaultProps = {
  createMode: false,
};

export default AdminModulesForm;
