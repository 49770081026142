import { RuleObject } from 'antd/lib/form';
import { decimalFormatter } from '~/utils/formatters';

const UNAVAILABILITY_ERROR_MESSAGE = `Quarterly pricing and availability should be added OR a reason selected from the dropdown.`;

export const requiredRule = {
  required: true,
  message: 'Please input a value for this field',
};

export const requiredFieldRule = {
  required: true,
  message: 'This field is required.',
};

export const notRequiredRules = [{ required: false }];

export const int4Rule = {
  validator: (_: RuleObject, value: string | number, limit: number) => {
    if (value > limit) {
      return Promise.reject(
        new Error(`Value cannot be greater than ${decimalFormatter(limit)}.`)
      );
    }

    return Promise.resolve();
  },
};

export const phoneNumberRule = {
  validator: (_: RuleObject, value: string) => {
    if (
      value.length > 0 &&
      !/^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
        value
      )
    ) {
      return Promise.reject(new Error(`Please input a valid phone number.`));
    }
    return Promise.resolve();
  },
};

export const requiredRuleNoMessage = {
  required: true,
  message: '',
};

export const requiredUnavailability = {
  required: true,
  message: UNAVAILABILITY_ERROR_MESSAGE,
};

export const enforceEmptyUnavailability = {
  validator: (_: RuleObject, value: string) => {
    if (value) {
      return Promise.reject(new Error(UNAVAILABILITY_ERROR_MESSAGE));
    }
    return Promise.resolve();
  },
};
